﻿import { Project } from "./project";

export class User {
    id: number;
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    receiver: string;
    iban: string;
    bic: string;
    role: string;
    token: string;
    projects: Project[];
    name: string;
    password_changed: string;
}
