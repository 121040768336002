import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  fname: string;
  lname: string;
  email: string;
  message: string;
  constructor() { }

  ngOnInit() {
    
  }
  processForm() {
    const allInfo = `My name is ${this.fname} ${this.lname}. My email is ${this.email}. My message is ${this.message}`;
    alert(allInfo); 
  }
}
