import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/_services';
import decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    //return true vor dem upload wieder entfernen
    // return true;
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;

    // const token = sessionStorage.getItem('token');
    // decode the token to get its payload
    // const tokenPayload = decode(token);

    //  || tokenPayload.role !== expectedRole
    if( this.authenticationService.currentUserValue ) {
      if ( this.authenticationService.currentUserValue.role === 'admin' ||  this.authenticationService.currentUserValue.role == expectedRole ) {
        return true;
      }
    }
    this.router.navigate(['login']);
    return false;
  }

}
