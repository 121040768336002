import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Project } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ProjectService {
    constructor(private http: HttpClient) { }

    // get all projects
    getAll() {
        return this.http.get<Project[]>(`${environment.apiUrl}/projects`);
    }

    getMy(uId: number) {
        return this.http.get<Project[]>(`${environment.apiUrl}/projects/` + uId);
    }

    // assign a new project to a user
    assignProject2User(uId, projectId) {
      let body = [];
      return this.http.put<any>(`${environment.apiUrl}/projects/` + uId + "/" + projectId, body);
    }

    // delte a users project assignement
    deleteProject2User(uId, projectId) {
      return this.http.delete<any>(`${environment.apiUrl}/projects/` + uId + "/" +  projectId);
    }
}
