import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../_services';
import { User } from '../_models';
import { MatDialog } from '@angular/material';
import { SessionOverComponent } from '@app/session-over/session-over.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  currentUser: User;
  navbarOpen = false;

  constructor(
      private router: Router,
      private authenticationService: AuthenticationService,
      public dialog: MatDialog
  ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SessionOverComponent, {
      width: '250px',
     
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     
    });
  }

  logout() {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
  }

  ngOnInit() {
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }


}
