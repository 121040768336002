﻿import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { LnuploadComponent } from './lnupload';
import { RnuploadComponent } from './rnupload';
import { MydataComponent } from './mydata';
import { DocumentsComponent } from './documents';
import { PayrollComponent } from './payroll/payroll.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ContactComponent } from './contact/contact.component';
import { AdminComponent } from './admin';
import { VerifiedComponent } from './verified/verified.component';
import { AuthGuard, RoleGuard } from './_guards';

import { MsalService, MsalGuard} from "@azure/msal-angular";

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    //{ path: 'verify', component:  },
    //{ path: 'callback', component:  },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    // { path: 'lnupload', component: LnuploadComponent, canActivate: [AuthGuard] },
    { path: 'mydata', component: MydataComponent, canActivate: [AuthGuard] },
    // { path: 'rnupload', component: RnuploadComponent, canActivate: [RoleGuard], data: { expectedRole: 'extern' } },
    { path: 'faqs', component: FaqsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'verify', component: VerifiedComponent },
    { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard] },
    { path: 'payroll', component: PayrollComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
    { path: 'admin', component: AdminComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' }
 },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
