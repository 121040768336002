﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    // only for admin
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    // only for admin
    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/users/${id}`);
    }

    // only for admin
    register(user: User) {
      console.log("registered the following user:");
      console.log(user);
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    update(user: User) {
        console.log("user service for id: " + user.id);
        return this.http.put<any>(`${environment.apiUrl}/users/${user.id}`,  user);
    }
    updateBankInfo(user: User) {
        console.log("user service for id: " + user.id);
        return this.http.put<any>(`${environment.apiUrl}/users/bankInfo/${user.id}`,  user);
    }

    delete(user: User) {
        return this.http.delete<any>(`${environment.apiUrl}/users/${user.id}`);
    }

    changePW(user: User, newPw: string) {
        console.log("change password ");
        return this.http.post<any>(`${environment.apiUrl}/users/changePassword/`+ user.id, {
                                                                    'password': newPw
                                                                  });
    }
}
