export class Upload {
  id: number;
  comment: string;
  fileName: string;
  month: string;
  year: string;
  hrs: string;
  project: string;
  type: string;
}
