﻿import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './_services';
import { User } from './_models';
import { MatDialog } from '@angular/material';
import { SessionOverComponent } from '@app/session-over/session-over.component';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    currentUser: User;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public dialog: MatDialog
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
        window.location.reload();
    }
    openDialog(): void {
        const dialogRef = this.dialog.open(SessionOverComponent, {
            disableClose: true
         
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
         
        });
      }
}