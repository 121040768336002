import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';


import { User, Project, Upload } from '@app/_models';
import { UserService, AuthenticationService, UploadService, ProjectService, AlertService } from '@app/_services';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule, MatChip, MatChipList} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  currentUser: User;
  currentUserSubscription: Subscription;
  projects: Project[] = [];
  newPw: string;
  uploads;
  rechnungen;
  payrolls;
  hrefPrefix: string;

  documents = [];

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private uploadService: UploadService,
    private projectService: ProjectService,
    private http: HttpClient,
    private alertService: AlertService,
) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
    });
}

  ngOnInit() {
    this.loadAllUploads();
    this.loadAllRechnungs();
    this.loadAllPayrolls();
    this.hrefPrefix = `${environment.apiUrl}`;
    this.uploadService.getAllPublicFiles().subscribe(documents => {
      this.documents = documents;
      // TODO Why replacement not working??
      this.documents.map(path => path.replace("public/", ""));
      console.log(documents);
    });
  }
  private loadAllUploads() {
    this.uploadService.getAll().subscribe(uploads => {
        console.log(uploads);
        this.uploads = uploads;
    });
  }

  private loadAllRechnungs() {
    this.uploadService.getAllRechnungen().subscribe(rechnungen => {
        console.log(rechnungen);
        this.rechnungen = rechnungen;
    });
  }

  private loadAllPayrolls() {
    this.uploadService.getAllPayrolls().subscribe(payrolls => {
        console.log(payrolls);
        this.payrolls = payrolls;
    });
  }
  private removeUpload(upload: Upload) {
    console.log("removing upload " + upload.id);
    this.uploadService.delete(upload.id).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("Der Leistungsnachweis wurde gelöscht.");
        this.loadAllUploads();
      } else {
        this.alertService.error(result);
      }
    });
  }

  private removeRechnung(rechnung) {
    console.log("removing rechnung " + rechnung.id);
    this.uploadService.deleteRechnung(rechnung.id).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("Die Rechnung wurde gelöscht.");
        this.loadAllRechnungs();
      } else {
        this.alertService.error(result);
      }
    });
  }
}
