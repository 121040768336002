import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { User, Project } from '@app/_models';
import { UserService, AuthenticationService, AlertService, ProjectService } from '@app/_services';

import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';

import { environment } from "@environments/environment";
import {HttpClient } from "@angular/common/http";


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit {
  addUseractivated = false;
  users: User[] = [];
  selectedUser: User;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = true;
  newfirstname: string;
  newlastname: string;
  newemail: string;
  projects: Project[] = [];
  openedUserProjects: Project[] = [];
  assignableUserProjects: Project[] = [];

  internalUsers: User[] = [];
  externalUsers: User[] = [];
  adminUsers: User[] = [];

  newProject4userName: Project;
  fixData: boolean = false;

  constructor(
    private userService: UserService,
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    private alertService: AlertService,
    private projectService: ProjectService,
    ) {}

ngOnInit() {
    this.loadAllUsers();
    this.loadAllProjects();
    // load form slider
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
}

  onSelect(user): void {
      this.selectedUser = user;
  }

  private loadAllUsers() {
    let interns = [];
    let externs = [];
    let admins = [];
    this.userService.getAll()
        .pipe(first())
        .subscribe(users => {
        this.users = users;
        console.log(this.users);
        this.users.map(user => {
          if (user.role === 'intern') {
            interns.push(user);
          }
          if (user.role === 'extern') {
            externs.push(user);
          }
          if (user.role === 'admin') {
            admins.push(user);
          }
        });
    });
    this.internalUsers = interns;
    this.externalUsers = externs;
    this.adminUsers = admins;
  }

  private delete(user){
    //console.log("delete user: " + user.firstname + " " + user.lastname + " " + user.email);
    this.userService.delete(user).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("User: " + user.firstname + " " + user.lastname + " " + "wurde gelöscht.");
      } else {
        this.alertService.error(result);
      }
    });
    this.loadAllUsers();
    this.fixData = !this.fixData;
  }

  private update(user){
    //console.log("updated user: " + user.id + " " + user.firstname + " " + user.lastname + " " + user.email);
    this.userService.update(user).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("User: " + user.firstname + " " + user.lastname + " " + "wurde aktualisiert.");
      } else {
        this.alertService.error(result);
      }
    });
    this.loadAllUsers();
    this.fixData = !this.fixData;
  }

  public create(firstname, lastname, email, addpanel, stepper){
    console.log("create user: " + firstname + " " + lastname + " " + email);
    this.http.post<any>(`${environment.apiUrl}/users/register`, { 'email': email,
                                                                  'firstname': firstname,
                                                                  'lastname': lastname,
                                                                  'name': firstname + " " + lastname })
              .subscribe(result => {
                if (result.result === 'success') {
                  stepper.reset();
                  addpanel.close();
                  this.loadAllUsers();
                  this.alertService.success("Ein neuer User wurde angelegt.");
                } else {
                  this.alertService.error(result.error);
                }
              });
  }

  private unselect(){
    delete this.selectedUser;
  }

  private loadAllProjects() {
    this.projectService.getAll().subscribe(projects => {
        this.projects = projects;
    });
  }

  private loadProjects(user) {
    // console.log("requesting projects of: " + user.id);
    this.projectService.getMy(user.id).subscribe(projects => {
        this.openedUserProjects = projects;
        this.assignableUserProjects = this.intersection(this.projects, projects);
    });
  }

  private closePanel(panel) {
    this.openedUserProjects = [];
    this.assignableUserProjects = [];
    panel.close();
  }

  private addProject2User(user, project) {
    // console.log("adde: " + project.name + " zu " + user.email);
    this.projectService.assignProject2User(user.id, project.id).subscribe( result => {
      if(result.result==='success') {
        this.alertService.success(user.firstname + " " + user.lastname + " wurde ein Projekt zugewiesen.");
        this.openedUserProjects.push(project);
        let index: number = this.assignableUserProjects.indexOf(project);
        this.assignableUserProjects.splice(index, 1);
      } else {
        this.alertService.error(result.error);
      }
    });
  }

  private intersection(array1, array2) {
    let intersect = [];

    array1.forEach(element1 => {
      let el1inarr2 = false;
      array2.forEach(element2 => {
        if( element1.id===element2.id ) el1inarr2 = true;
      });
      if (el1inarr2) {
        // nix
      } else {
        intersect.push(element1);
      }
    });

    return intersect;
  }

  private removeUserProjectAssignement(user, project) {
    //console.log("removing Assignement");
    this.projectService.deleteProject2User(user.id, project.id).subscribe( result => {
      if(result.result==='success') {
        this.alertService.success("Ein Projekt wurde von " + user.firstname + " " + user.lastname + " entfernt.");
        this.assignableUserProjects.push(project);
        let index: number = this.openedUserProjects.indexOf(project);
        this.openedUserProjects.splice(index, 1);
      } else {
        this.alertService.error(result.error);
      }
    });
  }
}


