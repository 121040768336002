﻿import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '@app/_services';

import { trigger, transition, animate, style, state, sequence } from '@angular/animations'

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.css'],
    animations:[ trigger('visibilityChanged', [
      transition(':enter', [
            style({ opacity: 0, transform: 'translateX(-40px)' }),
            animate(400, style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
            style({ opacity: 1, transform: 'translateX(0)' }),
            animate(400, style({ opacity: 0, transform: 'translateX(-40px)' }))
          ])
      ])
    ]
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            this.message = message;
            setTimeout(() => {
                this.message = null;
              }, 4000);
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
