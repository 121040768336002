import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { User, Project, Upload } from '@app/_models';
import { UserService, AuthenticationService, UploadService, ProjectService, AlertService } from '@app/_services';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule, MatChip, MatChipList} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";


@Component({
  selector: 'app-mydata',
  templateUrl: './mydata.component.html',
  styleUrls: ['./mydata.component.css']
})
export class MydataComponent implements OnInit {
  currentUser: User;
  currentUserSubscription: Subscription;
  projects: Project[] = [];
  newPw: string;
  uploads;
  rechnungen;
  bankInfo;
  hrefPrefix: string;


  constructor(
      private authenticationService: AuthenticationService,
      private userService: UserService,
      private uploadService: UploadService,
      private projectService: ProjectService,
      private http: HttpClient,
      private alertService: AlertService,
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
  }

  ngOnInit() {
    this.loadAllProjects();
    this.loadAllUploads();
    this.loadAllRechnungs();
    this.newPw = "";
    this.hrefPrefix = `${environment.apiUrl}`;
  }

  private loadAllProjects() {
    console.log("my ID: " + this.currentUser.id);
    this.projectService.getMy(this.currentUser.id).subscribe(projects => {
        this.projects = projects;
    });
    // this.projects = [{name:'ProjektA'}, {name:'ProjektB'}]
  }

  private loadAllUploads() {
    this.uploadService.getAll().subscribe(uploads => {
        console.log(uploads);
        this.uploads = uploads;
    });
  }

  private loadAllRechnungs() {
    this.uploadService.getAllRechnungen().subscribe(rechnungen => {
        console.log(rechnungen);
        this.rechnungen = rechnungen;
    });
  }
  

  private changePassword(user, newPw) {
    this.userService.changePW(user, newPw).subscribe(result => {
      if (result.result === 'success') {
        this.currentUser.password_changed = "true";
        this.alertService.success("Sie haben Ihr Passwort aktualisiert.");
      } else {
        this.alertService.error(result);
      }
    });
  }

  public update(user){
    console.log("updated user: " + user.id + " " + user.firstname + " " + user.lastname + " " + user.email);
    this.userService.update(user).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("Ihre Daten wurden aktualisiert.");
      } else {
        this.alertService.error(result);
      }
    });
  }

  public updateBankInfo(user){
   
    this.userService.updateBankInfo(user).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("Ihre Daten wurden aktualisiert.");
      } else {
        this.alertService.error(result);
      }
    });
  }

  private removeUpload(upload: Upload) {
    console.log("removing upload " + upload.id);
    this.uploadService.delete(upload.id).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("Der Leistungsnachweis wurde gelöscht.");
        this.loadAllUploads();
      } else {
        this.alertService.error(result);
      }
    });
  }

  private removeRechnung(rechnung) {
    console.log("removing rechnung " + rechnung.id);
    this.uploadService.deleteRechnung(rechnung.id).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("Die Rechnung wurde gelöscht.");
        this.loadAllRechnungs();
      } else {
        this.alertService.error(result);
      }
    });
  }

}
