﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService, AlertService } from '@app/_services';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService, public router: Router, private alertService: AlertService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
         //return true vor dem upload wieder entfernen
        // return true;
        const currentUser = this.authenticationService.currentUserValue;

        console.log(this.router.url);
        if (currentUser) {
          if(currentUser.role==='extern' && currentUser.password_changed==='false') {
            if(this.router.url!='/mydata'){
              // password not changed so redirect to settings page
              // this.router.navigate(['/mydata']);
              this.alertService.error('Bitte ändern Sie Ihr Passwort.');
            }
          }
        }

        if (currentUser || this.authenticationService.azureLoginSuccess) {
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
