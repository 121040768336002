﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '@app/_services';


import {Observable} from 'rxjs/Rx';
import {HttpClient, HttpHeaders } from "@angular/common/http";

import {BroadcastService} from "@azure/msal-angular";
import { MsalService} from "@azure/msal-angular";
import {Subscription} from "rxjs/Subscription";
import { environment } from "@environments/environment";


import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    email: string;


    //for msal
    public userInfo: any = null;
    private subscription: Subscription;
    public isIframe: boolean;

    constructor(
        private http: HttpClient,
        private broadcastService: BroadcastService,
        private authService: MsalService,

        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        public dialog: MatDialog,
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = '/';
        this.email = '';


        this.broadcastService.subscribe("msal:loginFailure", (payload) => {
          console.log("Azure login failure " + JSON.stringify(payload));
        });
        this.broadcastService.subscribe("msal:loginSuccess", (payload) => {
          //  lil hack for not having login phases
          this.authenticationService.azureLoginSuccess = true;
          console.log("Azure login success " + JSON.stringify(payload));
          this.authenticationService.azure_login(payload).pipe(first())
          .subscribe(
              data => {
                  this.router.navigate([this.returnUrl]);
              },
              error => {
                  this.alertService.error(error);
                  this.loading = false;
              });
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            this.alertService.error("Formulardaten ungültig.");
            return;
        }

        this.loading = true;
        // client side hashing not done because of SSL
        // let hashedPw = bcrypt(this.f.password.value);
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    // this.alertService.error(error);
                    this.alertService.error("Formulardaten ungültig.");
                    this.loading = false;
                });
    }

    azure_login() {
      console.log("login");
      this.authService.loginRedirect(["user.read", "user.readbasic.all"]);
      //  lil hack for not having login phases
      this.authenticationService.azureLoginSuccess = true;
    }

    azure_logout() {
      this.authService.logout();
    }


    private resend(valid: boolean) {
      if(valid) {
        this.http.post<any>(`${environment.apiUrl}/users/forgot`, { 'email': this.email }).subscribe( data => {
          if (data.result==='success'){
            this.alertService.success("Ihr Passwort wurde zurückgesetzt und eine Email versandt.");
          }
        });
      } else {
        this.alertService.error("Email ungültig.")
      }
    }

}
