import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectModule, MatCardModule, MatDividerModule } from '@angular/material';
import { first } from 'rxjs/operators';

import { UserService, AuthenticationService, AlertService, ProjectService, UploadService } from '@app/_services';

import {Observable} from 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { Upload, Project } from '@app/_models';
import { FormsModule } from '@angular/forms';
import { User } from '@app/_models';

const URL = `${environment.apiUrl}/payrollSave`;

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.css']
})
export class PayrollComponent implements OnInit {
  users: User[] = [];
  internalUsers: User[] = [];
  openedUserProjects: Project[] = [];
  openedUserPayrolls: Upload[] = [];
  assignableUserProjects: Project[] = [];
  assignableUserPayrolls: Upload[] = [];
  projects: Project[] = [];
  payrolls: Upload[] = [];
  hrefPrefix: string;

  constructor(
    private userService: UserService,
    private alertService: AlertService, 
    private http: HttpClient, 
    private router: Router, 
    private projectService: ProjectService,
    private uploadService: UploadService, 
    private authenticationService: AuthenticationService) { }
  monate: string[] = ['Januar', 'Februar', 'März', 'April', 'Mai',
                      'Juni', 'Juli', 'August', 'September', 'Oktober',
                      'November', 'Dezember'];
  jahre: string[];
  // ng2-file-upload
  public uploader: FileUploader = new FileUploader({
                                                    url: URL,
                                                    // queueLimit for max number files
                                                    queueLimit: 1,
                                                    allowedMimeType: ['pdf', 'application/pdf'], // will be loaded only PDF files
                                                    maxFileSize: 5 * 1024 * 1024, // 4 MB
                                                  });
  public hasBaseDropZoneOver: boolean = false;
  public formUpload: Upload;
  public projektnummern: string[];
  formValid = true;

  public fileOverBase(e: any): void {
    console.log("Datei kein PDF");
    this.hasBaseDropZoneOver = e;
  }

  


  ngOnInit() {
    let alertservice_tmp = this.alertService;
    this.uploader.onWhenAddingFileFailed = function(item: any, filter: any, options: any): any {
      console.log("ungültiges Dateiformat.");
      alertservice_tmp.error("ungültiges Dateiformat.");
      return { item, filter, options };
    }
    this.loadyear();
    this.loadAllUsers();
    this.hrefPrefix = `${environment.apiUrl}`;
    this.formUpload = new Upload();
    this.formUpload.project = "0";
    this.formUpload.year = "";
    this.formUpload.month = "";
    this.formUpload.comment = "";
    this.formUpload.hrs = "0";
    this.formUpload.id = 26;
    this.formUpload.type = "3";
  }

  checkFormValidity() {
    this.formValid = (this.formUpload.project && this.formUpload.year
                    && this.formUpload.month && this.formUpload.hrs
                    && this.uploader.queue.length > 0 && RegExp('^[0-9]*$').test(this.formUpload.hrs) );
  }

  public upload() {
    this.checkFormValidity();
    if (this.formValid){
        console.log("Form-Data");
        console.log(this.formUpload.project);
        console.log(this.formUpload.year);
        console.log(this.formUpload.month);
        console.log(this.formUpload.comment);
        console.log(this.formUpload.hrs);
        console.log(this.formUpload.type);
        console.log(this.formUpload.id);
        console.log(this.uploader.queue[0].file.name);
        this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
          form.append('projectid', this.formUpload.project); // note comma separating key and value
          form.append('month', this.formUpload.month);
          form.append('year', this.formUpload.year);
          form.append('hrs', this.formUpload.hrs);
          form.append('comment', this.formUpload.comment);
          form.append('type_id', this.formUpload.type);
          form.append('user_id', this.formUpload.id);
          form.append('file_name', this.uploader.queue[0].file.name);
          // form.append('filename', 'TestName');
          // The number of milliseconds between 1 January 1970 00:00:00 UTC and the given date.
          form.append('upload_time', new Date().valueOf()); // expected output: 823230245000
        };
        this.uploader.uploadAll();
        this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
          const res = JSON.parse(response);
          if(res.result == "success"){
            console.log("response"+(response));
            this.router.navigate(['']);
            this.alertService.success(res.error);
          } else {
            this.alertService.error(res.error);
          }
        };
    } else {
      this.alertService.error("Form ungültig");
    }
  }


  private loadyear(): void {
    const years = [];
    const z = (new Date()).getFullYear();
    const a = z - 1;
    for (let i = a; i <= z; i++) {
      years.push(i.toString());
    }
    this.jahre = years;
  }

  private loadProjects(user) {
    // console.log("requesting projects of: " + user.id);
    this.projectService.getMy(user.id).subscribe(projects => {
        this.openedUserProjects = projects;
        this.assignableUserProjects = this.intersection(this.projects, projects);
    });
  }

  private loadPayrolls(user) {
   
    this.uploadService.getMyPayrolls(user.id).subscribe(payrolls => {
        this.openedUserPayrolls = payrolls;
        this.assignableUserPayrolls = this.intersection(this.payrolls, payrolls);
    });
  }

  private intersection(array1, array2) {
    let intersect = [];

    array1.forEach(element1 => {
      let el1inarr2 = false;
      array2.forEach(element2 => {
        if( element1.id===element2.id ) el1inarr2 = true;
      });
      if (el1inarr2) {
        // nix
      } else {
        intersect.push(element1);
      }
    });

    return intersect;
  }

  private loadAllUsers() {
    let interns = [];
    let externs = [];
    let admins = [];
    this.userService.getAll()
        .pipe(first())
        .subscribe(users => {
        this.users = users;
        console.log(this.users);
        this.users.map(user => {
          if (user.role === 'intern') {
            interns.push(user);
          }
        
         
        });
    });
    this.internalUsers = interns;
  }

  private removePayrolls(user: Upload, payroll: Upload) {
    console.log("removing upload " + user.id);
    console.log("removing filename id " + payroll.id);
    console.log("removing filename " + payroll.fileName);
    console.log("removing filename project " + payroll.project);
    this.uploadService.deletePayroll(user.id, payroll.fileName).subscribe(result => {
      if (result.result === 'success') {
        this.alertService.success("Die Gehaltsabrechnung wurde gelöscht.");
        this.loadAllUsers();
      } else {
        this.alertService.error(result);
      }
    });
  }

}
